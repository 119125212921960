import axios from "axios";
export const axiosApi = axios.create({
  baseURL: "https://dppulse.com/backend",
  // baseURL: process.env.REACT_APP_DP_PULSE_API,
});

axiosApi.defaults.headers.common[
  "Authorization"
] = `Bearer ${localStorage.getItem("access")}`;

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    const statusCode = error?.response?.status;
    if (statusCode === 401) {
      window.location = '/landing';
      localStorage.clear();
      // location.reload();
    }
    return Promise.reject(error);
  }
);
